<template>
    <div class="wrap">
        <div class="title">My Profile</div>
        <div class="tit">Your profile is a record of your personal information, company and shipping address details.</div>
        <div style="height: 650px;" class="info">
            <div class="infotit">
                Personal Information
            </div>
            <div style="margin-left: 28px;">
                <el-form :model="personalInfo" :hide-required-asterisk="true" ref="personalInfo">
                    <div style="display: flex;align-items: center;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Full Name</p>
                            <el-form-item prop="nickName" :rules="[
                                { required: true, message: 'Name cannot be empty' },
                            ]">
                                <el-input v-model="personalInfo.nickName" style="width: 382px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="margin-left: 56px;">
                            <p style="font-weight: bold;margin: 17px 0 6px;">Country/Region</p>
                            <el-form-item prop="country">
                                <el-input v-model="personalInfo.country" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Email</p>
                            <el-form-item prop="email" :rules="[
                                { required: true, message: 'Please enter your email address', trigger: 'blur' },
                                { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                            ]">
                                <el-input v-model="personalInfo.email" style="width: 382px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="margin-left: 56px;">
                            <p style="font-weight: bold;margin: 17px 0 6px;">State</p>
                            <el-form-item prop="state">
                                <el-input v-model="personalInfo.state" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Phone number</p>
                            <el-form-item prop="phone">
                                <el-input v-model="personalInfo.phone" style="width: 382px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="margin-left: 56px;">
                            <p style="font-weight: bold;margin: 17px 0 6px;">City</p>
                            <el-form-item prop="city">
                                <el-input v-model="personalInfo.city" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Company Name</p>
                            <el-form-item prop="companyName">
                                <el-input v-model="personalInfo.companyName" style="width: 382px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="margin-left: 56px;">
                            <p style="font-weight: bold;margin: 17px 0 6px;">Address1</p>
                            <el-form-item prop="address">
                                <el-input v-model="personalInfo.address" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Job Title</p>
                            <el-form-item prop="position">
                                <el-input v-model="personalInfo.position" style="width: 382px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="margin-left: 56px;">
                            <p style="font-weight: bold;margin: 17px 0 6px;">Address2</p>
                            <el-form-item prop="addressTwo">
                                <el-input v-model="personalInfo.addressTwo" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: flex-end;margin-right: 13px;">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">ZIP Code</p>
                            <el-form-item prop="zipCode">
                                <el-input v-model="personalInfo.zipCode" style="width: 590px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item style="margin: 20px 13px 0 0;display: flex;justify-content: flex-end;">
                        <el-button :disabled="isClick" type="primary" @click="submitForm('personalInfo')"
                            style="width: 103px;font-size: 12px;">Save</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="info">
            <div class="infotit">
                Shipping Address
            </div>
            <div style="display: flex;">
                <div
                    style="width: 669px;height: 523px;border-right: 1px solid #D9D9D9;overflow: hidden;padding-left: 28px;">
                    <el-form :model="address" :hide-required-asterisk="true" ref="address">
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Country/Region</p>
                            <el-form-item prop="country" :rules="[
                                { required: true, message: 'country cannot be empty' },
                            ]">
                                <el-input v-model="address.country" style="width: 590px;"
                                    :validate-event="false"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">City</p>
                            <el-form-item prop="city" :rules="[
                                { required: true, message: 'city cannot be empty' },
                            ]">
                                <el-input v-model="address.city" style="width: 590px;" :validate-event="false"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Address1</p>
                            <el-form-item prop="address" :rules="[
                                { required: true, message: 'address1 cannot be empty' },
                            ]">
                                <el-input v-model="address.address" style="width: 590px;"
                                    :validate-event="false"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <p style="font-weight: bold;margin: 17px 0 6px;">Address2</p>
                            <el-form-item prop="addressTwo">
                                <el-input v-model="address.addressTwo" style="width: 590px;"
                                    :validate-event="false"></el-input>
                            </el-form-item>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <div>
                                <p style="font-weight: bold;margin: 17px 0 6px;">ZIP Code</p>
                                <el-form-item prop="zipCode">
                                    <el-input v-model="address.zipCode" style="width: 285px;"
                                        :validate-event="false"></el-input>
                                </el-form-item>
                            </div>
                            <div style="margin-left: 18px;">
                                <p style="font-weight: bold;margin: 17px 0 6px;">Contact Number</p>
                                <el-form-item prop="contactNumber">
                                    <el-input v-model="address.contactNumber" style="width: 285px;"
                                        :validate-event="false"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <el-form-item style="margin: 20px 35px 0 0;display: flex;justify-content: flex-end;">
                            <el-button type="primary" @click="submitForm1('address')"
                                style="width: 103px;font-size: 12px;">Add Address</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="margin: 20px 0 0 23px;">
                    <p style="font-weight: bold;">Address</p>
                    <div style="border-bottom: 1px solid #D9D9D9;width: 393px;">
                        <div style="font-size: 12px;margin-top: 13px;width: 344px;padding-bottom: 11px;">
                            You can select
                            previously saved and used addresses as the default shipping address.</div>
                    </div>
                    <div style="height: 373px;overflow: hidden;">
                        <div class="scrollable-content">
                            <div v-for="(item, index) in addresses" :key="index" class="adds">
                                <div>
                                    <input type="radio" :value="item.id" v-model="selectedItem"
                                        @click="handleRadioChange(item.id)" />
                                </div>
                                <div style="width: 313px;">
                                    <p>{{ item.address }}{{ item.addressTwo }}</p>
                                    <p style="margin-top: 5px;">{{ item.country }} {{ item.city }},{{ item.zipCode }}</p>
                                    <p style="margin-top: 5px;">{{ item.contactNumber }}</p>
                                </div>
                                <div @click="deleteadd(item.id)">
                                    <img src="../../assets/imgs/uploads/Vector1.png" alt=""
                                        style="width: 12px;height: 12px;cursor: pointer;margin-right: 3px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info" style="height: 370px;">
            <div class="infotit">
                Change Password
            </div>
            <div>
                <div style="margin-top: 31px;font-size: 12px;color: #959595;margin-left: 30px;">You can change your password
                    for security reasons or reset it if you forget.</div>
                <el-form :model="passForm" :rules="rules2" ref="restForm">
                    <div style="display: flex;justify-content: space-around;padding-left: 12px;">
                        <el-form-item prop="Pass">
                            <div style="font-weight: bold;">Current Password</div>
                            <el-input v-model="passForm.Pass" style="width: 497px;">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="newPass">
                            <div style="font-weight: bold;">New Password</div>
                            <el-input v-model="passForm.newPass" style="width: 497px;">
                            </el-input>
                        </el-form-item>
                    </div>
                    <el-form-item prop="confirmPass" style="display: flex;justify-content: flex-end;">
                        <div style="font-weight: bold;">Confirm your new password</div>
                        <el-input v-model="passForm.confirmPass" style="width: 497px;margin-right: 17px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item style="display: flex;justify-content: flex-end;margin-right: 17px;margin-top: 20px;">
                        <el-button type="primary" @click="updatePass"
                            style="font-size: 12px;width: 103px;">Update</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login.placeholder1')));
            } else {
                const passPattern = /^[a-zA-Z]\w{5,17}$/;
                if (passPattern.test(value)) {
                    this.$refs.restForm.validateField('confirmPass');
                    return callback();
                } else {
                    return callback(
                        new Error(this.$t('login.hint'))
                    );
                }
            }
        };
        let validateConfirmPass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('mine.confirmpassword1')));
            } else if (value !== this.passForm.newPass) {
                callback(new Error(this.$t('mine.confirmpassword2')));
            } else {
                callback();
            }
        };
        return {
            isClick: false,
            personalInfo: {
                nickName: '',
                country: '',
                city: '',
                email: '',
                phone: '',
                address: '',
                addressTwo: '',
                companyName: '',
                position: '',
                zipCode: '',
                state: '',
            },
            selectedItem: null,
            address: {
                country: '',
                city: '',
                address: '',
                addressTwo: '',
                zipCode: '200000',
                contactNumber: '',
                nickName: '',
                umsId: this.$store.getters.getUser.id
            },
            addresses: [],
            passForm: {
                Pass: '',
                newPass: '',
                confirmPass: ''
            },
            rules2: {
                newPass: [{ validator: validatePass, trigger: 'blur' }],
                confirmPass: [{ validator: validateConfirmPass, trigger: 'blur' }],
            }
        }
    },
    mounted() {
        this.activated()
        this.activated1()
        this.defaultadd()
    },

    methods: {
        ...mapActions(["setNickName"]),
        activated() {
            // this.$axios
            //     .get("/api/mall/member/" + this.$store.getters.getUser.id)
            //     .then(res => {
            //         if (res.data.code === 200) {
            //             this.personalInfo = res.data.data
            //         }
            //     })
            //     .catch(err => {
            //         return Promise.reject(err);
            //     });
            this.$axios
                .post("/api/mall/customer/personInfo", {
                    id: this.$store.getters.getUser.id
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.personalInfo = res.data.data
                        // console.log(this.personalInfo);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        activated1() {
            this.$axios
                .post("/api/mall/customer/getAddress", {
                    umsId: this.$store.getters.getUser.id
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.addresses = []
                        this.addresses = res.data.data
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        // saveInfo() {
        //     this.$refs['personalInfoForm'].validate((valid) => {
        //         if (valid) {
        //             this.$axios
        //                 .post("/api/mall/member/update", {
        //                     id: this.$store.getters.getUser.id,
        //                     nickName: this.personalInfo.nickName
        //                 })
        //                 .then(res => {
        //                     if (res.data.code === 200) {
        //                         this.setNickName(this.personalInfo.nickName)
        //                         this.notifySucceed(this.$t('mine.renew'));
        //                     } else {
        //                         this.notifyError(res.data.message);
        //                     }
        //                 })
        //                 .catch(err => {
        //                     return Promise.reject(err);
        //                 });
        //         } else {
        //             return false;
        //         }
        //     });
        // },
        submitForm(formName) {
            this.isClick = true;
            setTimeout(() => {
                this.isClick = false;
            }, 2000);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$axios
                        .post("/api/mall/customer/saveInfo", this.personalInfo)
                    this.$message({
                        message: 'Modified successfully',
                        type: "success",
                    });
                } else {
                    return false;
                }
            });
        },
        submitForm1(formName1) {
            this.$refs[formName1].validate((valid) => {
                if (valid) {
                    this.$axios
                        .post("/api/mall/customer/saveAddress", this.address)
                        .then(res => {
                            if (res.data.code === 200) {
                                this.activated1()
                                this.$message({
                                    message: 'Add successfully',
                                    type: "success",
                                });
                                this.address = {}
                                this.address.umsId = this.$store.getters.getUser.id
                            }
                        })
                        .catch(err => {
                            return Promise.reject(err);
                        });
                } else {
                    return false;
                }
            });
        },
        handleRadioChange(Id) {
            this.$axios
                .post("/api/mall/customer/changeStatus", {
                    id: Id,
                    userId: this.$store.getters.getUser.id
                }).then(res => {
                    if (res.data.code === 200) {
                        this.$message({
                            message: 'Switching the default address successfully',
                            type: "success",
                        });
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });

        },
        deleteadd(Id) {
            this.$axios
                .post("/api/mall/customer/delAddress", {
                    id: Id,
                }).then(res => {
                    if (res.data.code === 200) {
                        this.activated1()
                        this.$message({
                            message: 'Del successfully',
                            type: "success",
                        });
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });

        },
        defaultadd() {
            this.$axios
                .post("/api/mall/customer/getDefaultAddress", {
                    userId: this.$store.getters.getUser.id,
                })
                .then(res => {
                    if (res.data.code === 200) {
                        if (res.data.data) {
                            this.selectedItem = res.data.data.addressId
                        }
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        updatePass() {
            if (this.passForm.Pass == this.$store.getters.getUser.password) {
                this.$refs['restForm'].validate((valid) => {
                    if (valid) {
                        this.$axios
                            .post("/api/mall/member/updatePass", {
                                id: this.$store.getters.getUser.id,
                                password: this.passForm.newPass
                            })
                            .then(res => {
                                if (res.data.code === 200) {
                                    this.passForm.newPass = ''
                                    this.passForm.confirmPass = ''
                                    this.notifySucceed(this.$t('mine.renew1'));
                                } else {
                                    this.notifyError(res.data.message);
                                }
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    } else {
                        return false;
                    }
                });
            } else {
                this.$message.error('Old password error!');
                return false;
            }
        }
    }
}
</script>

<style scoped>
/* .wrap {
    margin-left: 20px;
    width: 1105px;
} */

.title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 17px;
}

.adds {
    margin-top: 15px;
    padding-bottom: 14px;
    width: 393px;
    height: 90px;
    display: flex;
    color: #545454;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
}

.scrollable-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    /* 启用滚动 */
    scrollbar-color: #959595 transparent;
    /* 使用透明颜色隐藏滚动条 */
    /* 隐藏滚动条 */
    scrollbar-width: thin;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

/* .scrollable-content::-webkit-scrollbar {
  width: 5px; 
} */

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: #959595;
    /* 滚动条 thumb 的背景颜色 */
    border-radius: 6px;
    /* 滚动条 thumb 圆角 */
}

.scrollable-content::-webkit-scrollbar-track {
    background-color: transparent;
    /* 使用透明颜色隐藏轨道 */
    border: none;
    /* 隐藏可能出现的边框 */
}

.scrollable-content::-webkit-scrollbar-button {
    color: transparent;
}

.tit {
    font-size: 13px;
    color: #959595;
}

.info {
    margin-top: 12px;
    height: 570px;
    border: 1px solid #EAEAEA;
    border-radius: 15px;
}

.infotit {
    height: 46px;
    background-color: #EAEAEA;
    border-radius: 15px 15px 0 0;
    padding-left: 28px;
    font-size: 18px;
    font-weight: bold;
    line-height: 46px;
}

.el-form-item {
    margin-bottom: 0px;
}
</style>